<template>
  <main class="no-main">
    <div class="ps-breadcrumb">
      <div class="container-fluid-sy">
        <ul class="ps-breadcrumb__list">
          <li class="active">
            <router-link :to="{ name: 'home' }">Home</router-link>
          </li>
          <li class="active"><router-link :to="{ name: 'dashboard' }">My Account</router-link></li>
          <li><a href="javascript:void(0);"> Orders history</a></li>
        </ul>
      </div>
    </div>
    <section class="section--shopping-cart">
      <div class="container shopping-container">
        <h2 class="page__title">Orders History </h2>
        <template v-if="!isLoading">
          <template v-if="orders.length">
            <list-item v-for="(order, index) in orders" :key="index" :item="{
                sn: index+1,
                id:1,
                title: `${order.id} / ${labelHtml(order.status_label)}`,
                details:[
                    {label:'Date',value: date(order.created_at) },
                    {label:'Total',value: `${order.order_currency_code} ${order.formated_grand_total}`},
                    {label:'Status',value: `${order.status_label}`},
                ],
            }">
              <template v-slot:action>

                <router-link
                    :to="{
                      name: 'user-orders-invoice',
                      params: { id: order.id },
                    }"
                    class="nav-link success"
                  >
                      <i class="icon-eye"></i> View
                  </router-link>
                <a href="" @click.prevent="onMakeReorder(order.id)" class="nav-link"><i class="icon-cart"></i> Reorder</a>
              </template>
            </list-item>
          </template>
          <template v-else>
            <div style="text-align:center">
              No orders found.
            </div>
          </template>
      </template>
        <template v-else>
          <div v-for="(item, index) in 6" :key="index">
            
            <Skeletor height="20px" style="margin-bottom:10px;padding-bottom:20px"/>
            <Skeletor height="5px" width="30%" style="margin-bottom:10px" />

          </div>

        </template>
      </div>
    </section>
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import "vue-skeletor/dist/vue-skeletor.css";
import { Skeletor } from "vue-skeletor";
import moment from "moment";
import ListItem from '../Components/ListItem.vue';

export default {
  mounted() {
    this.fetchOrders();
  },

  components: {
    Skeletor,
    ListItem,
  },

  computed: {
    ...mapGetters("orders", ["orders", "isLoading","loaded",'findById'])
  },

  methods: {
    ...mapActions("orders", ["fetchOrders"]),
     ...mapActions('cart',['addToCart']),

    onMakeReorder(orderID){
      let order = this.findById(orderID),self = this;

      order.items.forEach(item => {
        self.addToCart({product_id:item.additional.product_id,quantity:item.additional.quantity})   
        this.addToCart(self)     
      });
      

    },

    moment: function (date) {
      return moment(date);
    },
    date: function (date) {
      return moment(date).format("YYYY-MM-DD");
    },
    labelHtml(status){
      let html = '';
      switch (status) {
        case 'Pending':
          html = '<span class="badge badge-warning">'+status +'</span>';
          break;
        case 'Completed':
          html = '<span class="badge badge-success">'+status +'</span>';
          break;
        case 'Cancelled':
          html = '<span class="badge badge-danger">'+status +'</span>';
          break;
      
      
        default:
          break;
      }

      return html;
    }
  },
};
</script>


<style lang="scss" scoped>
.labl_pending {
  background-color: #ffc107;
  color: #ffff;
  font-size: 12px;
  border: none;
}
.labl_compleet {
  background-color: #28a745;
  color: #ffff;
  font-size: 12px;
  border: none;
}
.labl_cancel {
  background-color: #ff2d21;
  color: #ffff;
  font-size: 12px;
  border: none;
}
.labl_View {
  background-color: #28a745;
  color: #ffff;
  font-size: 12px;
}
.labl_reorder {
  background-color: #ff7200;
  color: #ffff;
  font-size: 12px;
}
.btn-status {
  width: 80px;
}
table {
  text-align: center;
}
table th {
  color: #2e2e2e;
  font-weight: bold;
}
table td {
  color: rgb(34, 34, 34);
}
@media (max-width: 1024px) {
  table {
    width: 900px;
  }
}
</style>