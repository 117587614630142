<template>
    <div class="cr-card-item pd-0-force" :class="item.theme ? item.theme : ''">
        <div class="cr-sn" v-if="item.sn">
            <div class="text">
                {{ item.sn }}
            </div>
        </div>
        <div class="cr-card-body pd-10 mg-l-0-force">
            <div class="cr-card-name">
                <a href="javascript:void(0)" v-html="item.title || 'Unknown'"></a>
            </div>
            <ul>
                <li v-for="(l, index) in item.details" :key="index"><span>{{ l.label }}:</span> <span v-html="l.value"></span></li>
            </ul>
        </div>  
        
        <div class="flex-1 mg-x-auto pd-r-10 action-visible" v-if="action">
            <div class="float-right">
            <nav class="nav">
                <slot name="action"></slot>
            </nav>
            </div>
        </div>
        </div>
</template>
<script>
export default {
    props:{
        item:{
            type:Object,
            default: null
        },
        action:{
            type:Boolean,
            default:true
        }
    }
}
</script>